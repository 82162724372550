<template>
  <section>
    <v-layout>
      <v-flex xs12>
        <v-row class="mt-5 ml-1">
          <div>{{ `${"01-2022-S424"} / ${"Proveedor"} / ${"Amazon US"}` }}</div>
        </v-row>
        <v-row class="align-center">
          <v-col cols="12" sm="6" md="2" lg="1">
            <div class="text-caption">Proveedor</div>
            <p>{{ `Amazon US` }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <perfil-proveedor>
              <template #default="{ attrs, on }">
                <v-btn color="secondary" small v-bind="attrs" v-on="on"
                  >Ver informacion</v-btn
                >
              </template>
            </perfil-proveedor>
          </v-col>
        </v-row>
        <v-row class="flex-column">
          <v-col>
            <div>Lista de insumos</div>
          </v-col>

          <v-col cols="12" md="9">
            <v-data-table :headers="headers" hide-default-footer :items="items">
              <template #[`item.acciones`]="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="$router.push('/detalles-insumo')"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span> Ver </span>
                </v-tooltip>
              </template>
              <template #[`item.total`]="{ item }">
                <td>{{ `${item.total}` }}</td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import PerfilProveedor from "../../../components/PerfilProveedorComponent.vue";
export default {
  name: "DetalleProveedorComponent",
  components: {
    PerfilProveedor,
  },
  data() {
    return {
      headers: [
        {
          text: "Correlativo",
          value: "correlativo",
        },
        {
          text: "Mercancia",
          value: "mercancia",
        },
        {
          text: "Unidad de medida",
          value: "unidad_medida",
        },
        {
          text: "Total($)",
          value: "total",
        },
        {
          text: "OBS",
          value: "obs",
        },
        {
          text: "Acciones",
          align: "center",
          value: "acciones",
        },
      ],
      items: [
        {
          correlativo: 1,
          mercancia: "Lapiceros azules",
          unidad_medida: "Unidad",
          total: "70.00",
          obs: "54110",
        },
      ],
    };
  },
};
</script>