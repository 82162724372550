<template>
  <section fluid>
    <v-layout>
      <v-flex>
        <v-row class="mt-5">
          <p>Creacion de orden de compra</p>
        </v-row>
        <v-row>
          <v-col cols="12" sm="2">
            {{ `Amazon` }}
            <v-btn icon>
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <estrellas-component :start="3" />
          </v-col>
          <v-col cols="12" sm="2">
            {{ `$125.00` }}
            <div class="text-caption">Monto</div>
          </v-col>
          <v-col cols="12" sm="4">
            {{ `14111500 Papel bond blanco 96%` }}
            <div class="text-caption">{{ `50 Resmas` }}</div>
          </v-col>
        </v-row>

        <v-stepper v-model="e1" color="secondary" elevation="0" width="80%">
          <v-stepper-header class="header-step">
            <v-stepper-step :complete="e1 > 1" step="1">
              Datos del contrato
            </v-stepper-step>

            <v-divider inset class="divider"></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Etapas
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1" class="content">
              <datos-contrato @next="e1 = 2" />
            </v-stepper-content>

            <v-stepper-content step="2" class="content">
              <etapas
                @regresar="e1 = 1"
                @abrirModal="agregarEtapa = true"
                :items="items"
                @eliminar="eliminarItem"
                @crearEtapa="crearEtapa = true"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <modal-agregar-etapa
          ref="modalEtapa"
          v-model="agregarEtapa"
          @close="agregarEtapa = false"
          @agregar="agregarLista"
          :items="items"
        />
        ../../components/EstrellasComponent.vue
        <modal-crear-etapa v-model="crearEtapa" @close="crearEtapa = false" />
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import EstrellasComponent from "../../components/EstrellasComponent.vue";
import DatosContrato from "./components/DatosContratoComponent.vue";
import Etapas from "./components/EtapasComponent.vue";
import ModalAgregarEtapa from "./components/ModalAgregarEtapaComponent.vue";
import ModalCrearEtapa from "./components/ModalCrearEtapaComponent.vue";
export default {
  name: "creacionOrdenCompraView",
  components: {
    EstrellasComponent,
    DatosContrato,
    Etapas,
    ModalAgregarEtapa,
    ModalCrearEtapa,
  },
  data() {
    return {
      e1: 1,
      agregarEtapa: false,
      crearEtapa: false,
      items: [
        {
          etapa: 1,
          porcentaje: 25,
          cantidad: 400,
          inicio: "2022-09-07",
          fin: "2022-09-07",
        },
      ],
    };
  },
  methods: {
    agregarLista(etapa) {
      this.items.push(etapa);
      this.agregarEtapa = false;
      
      this.$refs.modalEtapa.resetInputs();
    },
    eliminarItem(items) {
      this.items = items;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-step {
  background-color: #f4f7fd;
}

.content {
  background-color: #f4f7fd;
}
</style>