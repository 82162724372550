<template>
  <v-progress-linear :value="etapasPorcent" color="secondary" height="15">
    <strong class="styleText">{{ Math.ceil(etapasPorcent) }}%</strong>
  </v-progress-linear>
</template>
<script>
export default {
  name: "ProgresoPorcentajeComponent",
  props: ["etapasPorcent"],
};
</script>
<style lang="scss" scoped>
.styleText {
  color: white;
}
</style>