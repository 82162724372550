<template>
  <v-dialog v-model="value" width="1000">
    <v-card class="pa-5" width="1000">
      <v-card-text>
        <v-row class="flex-column">
          <p class="text-h6">Agregar etapa</p>
          <div class="text-caption">{{ `14111500 Papel bond blanco 96%` }}</div>
          <div class="text-caption">{{ `400 Resmas` }}</div>
        </v-row>
        <v-row class="mt-5">
          <p>Avance actual</p>
          <progreso-porcentaje :etapasPorcent="etapasPorcentaje"/>
        </v-row>
        <v-form class="mt-5">
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                label="Cantidad del bien"
                outlined
                v-model="cantidadBien"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="Porcentaje de avance"
                type="number"
                outlined
                v-model="porcentajeAvance"
                suffix="%"
                max-value="100"
                :error-messages="porcentajeAvanceError"
                @input="$v.porcentajeAvance.touch"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-menu
                v-model="campofechaInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaInicio"
                    label="Fecha y hora de inicio"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :error-messages="fechaInicioError"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  @input="campofechaInicio = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4">
              <v-menu
                v-model="campofechaFin"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaFin"
                    label="Fecha y hora de inicio"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :error-messages="fechaFinError"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFin"
                  @input="campofechaFin = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <p>Avance incluyendo etapa</p>
          <v-progress-linear
            :value="addetapasPorcent"
            color="secondary"
            height="15"
          >
            <strong class="styleText"
              >{{ Math.ceil(addetapasPorcent) }}%</strong
            >
          </v-progress-linear>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-5">
          <v-btn
            small
            outlined
            color="secondary"
            class="mr-1"
            @click="$emit('close')"
            >Volver</v-btn
          >

          <v-btn small color="secondary" @click="agregar()">Agregar</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import ProgresoPorcentaje from "./ProgresoPorcentajeComponent.vue"
export default {
  name: "ModalAgregarEtapaComponent",
  props: ["value", "items"],
  components: {ProgresoPorcentaje},
  data() {
    return {
      cantidadBien: null,
      porcentajeAvance: null,
      campofechaInicio: false,
      fechaInicio: "2022-09-25",
      campofechaFin: false,
      fechaFin: "2022-09-25",
    };
  },
  validations: {
    porcentajeAvance: {
      required,
      max: (function () {
        const sum = Number(this.etapasPorcentaje) + Number(this.porcentajeAvance);
        return sum && sum <= 100;
      }),
      numeric,
    },
    fechaInicio: { required },
    fechaFin: { required },
  },
  computed: {
    etapasPorcentaje() {
      return this.items.reduce((a, c) => Number(a) + Number(c.porcentaje), 0);
    },
    /**
     * @description modifica barra de progreso segun campo de porcentaje
     */
    addetapasPorcent() {
      return Number(this.etapasPorcentaje) + Number(this.porcentajeAvance);
    },

    /**
     * @description validaciones vuelidate
     */
    porcentajeAvanceError() {
      const errors = [];
      if (!this.$v.porcentajeAvance.$dirty) return errors;
      !this.$v.porcentajeAvance.required && errors.push("Campo requerido");
      !this.$v.porcentajeAvance.numeric && errors.push("Valor minimo 0");
      !this.$v.porcentajeAvance.max && errors.push("Valor máximo 100");

      return errors;
    },
    fechaInicioError() {
      const errors = [];
      if (!this.$v.fechaInicio.$dirty) return errors;
      !this.$v.fechaInicio.required && errors.push("Campo requerido");
      return errors;
    },
    fechaFinError() {
      const errors = [];
      if (!this.$v.fechaFin.$dirty) return errors;
      !this.$v.fechaFin.required && errors.push("Campo requerido");
      return errors;
    },
  },
  methods: {
    agregar() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      let data = {};

      data.etapa = this.items.length + 1;
      data.cantidad = 400;
      data.porcentaje = this.porcentajeAvance;
      data.inicio = this.fechaInicio;
      data.fin = this.fechaFin;
      this.$emit("agregar", data);
    },
    resetInputs() {
      this.porcentajeAvance = null;
    },
  },
};
</script>
<style lang="scss" scoped>
  .styleText {
    color: white;
  }
  </style>