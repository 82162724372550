<template>
  <section>
    <v-layout>
      <v-flex>
        <v-row class="align-center">
          <v-col cols="12" md="6">
            <p class="text-h6 primary--text mb-0">
              Solicitudes de obras, bienes o servicios
            </p>
            <p class="mb-0">Unidad de Talento Humano</p>
            <p>Ejercicio 2022</p>
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-end">
            <v-btn color="secondary" small @click="seleccion()">
              Agregar solicitud
            </v-btn>
          </v-col>
        </v-row>
        <stats-component :info="itemsFondos" class="mb-5" />
        <v-row class="pt-5">
          <p>Listado de solicitudes</p>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-select outlined dense label="Unidad" />
          </v-col>
          <v-col cols="12" md="2">
            <v-select outlined dense label="Tipos de compras" />
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="menuDataRange"
              v-model="menuDataRange"
              :close-on-content-click="false"
              :return-value.sync="fechas"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="dateRangeText"
                  label="Desde - Hasta"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker v-model="fechas" range locale="es-Es">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuDataRange = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuDataRange.save(fechas)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-select outlined dense label="Estado" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="solicitudes"
              hide-default-footer
            >
              <template v-slot:[`item.estado`]="{ item }">
                <v-chip
                  class="white--text"
                  v-if="item.estado === 1"
                  color="green accent-3"
                  label
                >
                  Aprobado por unidad
                </v-chip>

                <v-chip
                  class="white--text"
                  v-if="item.estado === 2"
                  color="yellow"
                  label
                >
                  Con observacion
                </v-chip>

                <v-chip class="white--text" v-if="item.estado === 3" label>
                  Elaborada
                </v-chip>
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      @click="verSolicitud(item)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span> Ver </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import moment from "moment-timezone";

import StatsComponent from "../../components/StatsComponent.vue";
export default {
  name: "listaAprobacionUACIView",
  components: {
    StatsComponent,
  },
  data: () => ({
    menuDataRange: false,
    fechas: ["05/09/2022", "07/09/2022"],
    itemsFondos: [
      {
        id: 1,
        value: "0",
        title: "Aprobadas",
        color: "green accent-3",
      },
      {
        id: 2,
        value: "1",
        title: "Pendientes",
        color: "secondary",
      },
      {
        id: 3,
        value: "10",
        title: "Con observaciones",
        color: "yellow",
      },
    ],
    solicitudes: [
      {
        correlativo: "01-2022-S001",
        unidad_solicitante: "Unidad de Retención de Talento Humano",
        fecha_creacion: "07/03/2022 3:05PM",
        tipo_compra: "Proceso de compra",
        estado: 1,
      },
      {
        correlativo: "02-2022-S002",
        unidad_solicitante: "Unidad de Retención de Talento Humano",
        fecha_creacion: "08/03/2022 3:35PM",
        tipo_compra: "Proceso de compra",
        estado: 2,
      },
      {
        correlativo: "02-2022-S002",
        unidad_solicitante: "Unidad de Retención de Talento Humano",
        fecha_creacion: "08/03/2022 3:35PM",
        tipo_compra: "Proceso de compra",
        estado: 3,
      },
    ],
    headers: [
      {
        text: "Correlativo",
        align: "start",
        sortable: false,
        value: "correlativo",
      },
      {
        text: "Unidad solicitante",
        align: "start",
        sortable: false,
        value: "unidad_solicitante",
      },
      {
        text: "Fecha de creación",
        align: "center",
        sortable: false,
        value: "fecha_creacion",
      },
      {
        text: "Tipo de compra",
        align: "center",
        sortable: false,
        value: "tipo_compra",
      },
      {
        text: "Estado",
        align: "center",
        sortable: false,
        value: "estado",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "acciones",
      },
    ],
  }),
  computed: {
    dateRangeText() {
      const primeraFecha = moment(this.fechas[0]).format("DD/MM/YYYY");
      const segundaFecha = moment(this.fechas[1]).format("DD/MM/YYYY");
      return primeraFecha + " - " + segundaFecha;
    },
  },
  methods: {
    seleccion() {
      //this.$router.push("/seleccion_tipo_compra");
    },
    verSolicitud() {
      this.$router.push("/lista-proveedores");
    },
  },
  created() {},
};
</script>