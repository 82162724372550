<template>
  <v-dialog v-model="value" width="500">
    <v-card width="500" class="pa-5">
      <v-card-title>
        <v-row>
          <p class="secundary-text text-h6">
            ¿Deseas crear la orden de compra?
          </p>
        </v-row>
      </v-card-title>
      <v-card-text>
        <p>
          De confirmarse la siguiente acción, los cambios no serán reversibles.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-5 justify-center">
          <v-btn
            small
            outlined
            color="secondary"
            class="mr-1"
            @click="$emit('close')"
            >Cancelar</v-btn
          >

          <v-btn small color="secondary" @click="$emit('close')">Aceptar</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ModalCrearEtapaComponent',
  props: ["value"],
};
</script>