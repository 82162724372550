<template>
  <v-form ref="form">
    <v-row class="mt-1">
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          label="Número de orden de compra"
          v-model="form.numOrdenCompra"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          label="Número interno de orden de compra"
          v-model="form.numInternOrdenCompra"
          :error-messages="numInternOrdenCompraError"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8">
        <v-text-field
          outlined
          label="Nombre del contrato"
          v-model="form.nombreContrato"
          :error-messages="nombreContratoError"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-menu
          v-model="vigencia"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.fecha"
              label="Vigencia a partir de"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              :error-messages="vigenciaError"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.fecha"
            @input="vigencia = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8">
        <v-text-field
          outlined
          label="Monto adjudicado"
          v-model="form.monto"
          :error-messages="montoError"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8">
        <v-text-field
          outlined
          label="Plazo contractual"
          v-model="form.plazo"
          suffix="Días"
          :error-messages="plazoError"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8">
        <v-select
          :items="items"
          item-text="nombre"
          item-value="id"
          chips
          outlined
          label="Copias a emitir"
          v-model="form.copias"
          multiple
          :error-messages="copiasError"
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="ma-2">
      <v-btn small outlined color="secondary" class="mr-1">Cancelar</v-btn>
      <v-btn small color="secondary" @click="siguiente()">Continuar</v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "DatosContratoComponent",
  data() {
    return {
      vigencia: false,
      form: {
        numOrdenCompra: null,
        numInternOrdenCompra: null,
        nombreContrato: null,
        fecha: null,
        monto: null,
        plazo: null,
        copias: null,
      },
      items: [
        { id: 1, nombre: "UCP" },
        { id: 2, nombre: "Contratista" },
        { id: 3, nombre: "Finanzas" },
      ],
    };
  },
  validations: {
    form: {
      numInternOrdenCompra: { required },
      nombreContrato: { required },
      fecha: { required },
      monto: { required },
      plazo: { required },
      copias: { required },
    },
  },
  computed: {
    numInternOrdenCompraError() {
      const errors = [];
      if (!this.$v.form.numInternOrdenCompra.$dirty) return errors;
      !this.$v.form.numInternOrdenCompra.required &&
        errors.push("Campo requerido");
      return errors;
    },
    nombreContratoError() {
      const errors = [];
      if (!this.$v.form.nombreContrato.$dirty) return errors;
      !this.$v.form.nombreContrato.required && errors.push("Campo requerido");
      return errors;
    },
    vigenciaError() {
      const errors = [];
      if (!this.$v.form.fecha.$dirty) return errors;
      !this.$v.form.fecha.required && errors.push("Campo requerido");
      return errors;
    },
    montoError() {
      const errors = [];
      if (!this.$v.form.monto.$dirty) return errors;
      !this.$v.form.monto.required && errors.push("Campo requerido");
      return errors;
    },
    plazoError() {
      const errors = [];
      if (!this.$v.form.plazo.$dirty) return errors;
      !this.$v.form.plazo.required && errors.push("Campo requerido");
      return errors;
    },
    copiasError() {
      const errors = [];
      if (!this.$v.form.copias.$dirty) return errors;
      !this.$v.form.copias.required && errors.push("Campo requerido");
      return errors;
    },
  },
  methods: {
    siguiente() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      this.$emit("next");
    },
  },
};
</script>