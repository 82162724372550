<template>
  <div>
    <v-rating
      v-model="start"
      :background-color="color || 'indigo lighten-3'"
      :color="color || 'indigo'"
      :size="size || 15"
      dense
      />
  </div>
</template>
<script>
export default {
  name: "EstrellasComponent",
  props: {
    start: {
      type: Number,
      required: false,
    },
    size: {
      type: Number,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
};
</script>