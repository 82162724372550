<template>
  <section>
    <v-layout>
      <v-flex>
        <v-row class="mt-5 ml-1">
          <div>
            {{
              `${"01-2022-S424"} / ${"Proveedor"} / ${"Amazon US"} / ${"14111500 Papel bond - Resma"}`
            }}
          </div>
        </v-row>
        <v-row class="flex-column ma-5">
          <v-col cols="12" sm="6">
            <div class="text-h6">{{ `Papel bond multipropósito` }}</div>
            <div class="text-caption">
              {{ `Amazon basics - resma de papel para impresora - 500 hojas` }}
            </div>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="12" md="6" lg="4">
            <ProductZoomer
              :base-images="imagenes"
              :base-zoomer-options="zoomerOptions"
            />
          </v-col>
          <v-col cols="12" md="3" lg="3">
            <div class="text-caption">OBS</div>
            <div>{{ `11121607 - Papel bond` }}</div>
            <div class="text-caption">Presentacion</div>
            <div>{{ `Resma celeste` }}</div>
            <div class="text-caption">Unidad de venta</div>
            <div>{{ `Unidad` }}</div>
            <div class="text-caption">Detalle</div>
            <div>
              {{
                `Resma celeste 96, 8.5 x 11 pulgadas, 5 Libras, 500 hojas, papel para copias`
              }}
            </div>
            <div class="text-caption">Precio</div>
            <div>{{ `$2.50` }}</div>
          </v-col>
        </v-row>
        <v-row class="mt-5 flex-column">
          <div>Insumos solicitados</div>
          <v-col cols="12"  md="9" lg="6">
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer
            >
            <template #[`item.precio`]="{item}">
              <td>{{`$${item.precio}`}}</td>
            </template>
            <template #[`item.total`]="{item}">
              <td>{{`$${item.total}`}}</td>
            </template>
          </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </section>
</template>
<script>
export default {
  name: "DetalleInsumoComponent",
  data() {
    return {
      imagenes: {
        // optional, if not present will use normal_size instead
        thumbs: [
          {
            id: "1",
            url: "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
          },
        ],
        // required
        normal_size: [
          {
            id: "1",
            url: "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
          },
        ],
        //optional, if not present will use normal_size instead
        large_size: [
          {
            id: "1",
            url: "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
          },
        ],
      },
      zoomerOptions: {
        zoomFactor: 2,
        pane: "container",
        hoverDelay: 300,
        namespace: "zoomer-bottom",
        move_by_click: false,
        scroll_items: 0,
        choosed_thumb_border_color: "#dd2c00",
        scroller_position: "bottom",
        zoomer_pane_position: "right",
        scroll_items: 1, /// array length
      },
      headers: [
        { text: "Correlativo", value: "correlativo" },
        { text: "Mes", value: "mes" },
        { text: "Cantidad", align: "center", value: "cantidad" },
        { text: "Precio Unitario", value: "precio" },
        { text: "Total", value: "total" },
      ],
      items: [
        {
          correlativo: 1,
          mes: "Febrero",
          cantidad: "20",
          precio: "2.50",
          total: "50.00",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.zoomer-bottom-base-container {
  width: 100% !important;
  height: auto !important;
  column-count: 3;
  .preview-box {
    width: 100%;
    height: auto;
    column-count: 3;
  }
  @media (min-width: 1264px) {
    .preview-box {
      width: 90%;
    }
  }

  @media (min-width: 1264px) {
    .thumb-list {
      width: 90% !important;
    }
  }
}

div.thumb-list {
  display: flex !important;
  justify-content: space-around !important;
  height: auto !important;
  width: 100% !important;

  img {
    margin-right: 10px;
    width: 65px !important;
    height: 65px !important;
    object-fit: contain;
  }
}
img.zoomer-control {
  display: none !important;
}
</style>