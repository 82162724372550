<template>
  <div class="pa-5">
    <v-row class="ml-2">
      <p>Agregue la cantidad de etapas que desee hasta completar el 100%</p>
    </v-row>

    <progreso-porcentaje :etapasPorcent="etapasPorcent" />

    <v-row class="ma-5">
      <v-btn color="secondary" @click="$emit('abrirModal')"> Agregar </v-btn>
    </v-row>

    <v-data-table hide-default-footer :headers="headers" :items="items">
      <template #[`item.porcentaje`]="{ item }">
        <td>{{ item.porcentaje }}%</td>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="eliminarRegistro(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-row class="mt-5">
      <v-btn
        small
        color="secondary"
        outlined
        @click="$emit('regresar')"
        class="mr-1"
        >Anterior</v-btn
      >

      <v-btn v-if="etapasPorcent < 100" small color="secondary">Cerrar</v-btn>
      <v-btn
        v-else-if="etapasPorcent === 100"
        small
        color="secondary"
        @click="$emit('crearEtapa')"
        >Crear</v-btn
      >
    </v-row>
  </div>
</template>
<script>
import ProgresoPorcentaje from "./ProgresoPorcentajeComponent.vue";
export default {
  name: "EtapasComponent",
  props: ["items"],
  components: { ProgresoPorcentaje },
  data() {
    return {
      headers: [
        { text: "Etapa", value: "etapa" },
        { text: "Porcentaje", value: "porcentaje" },
        { text: "Cantidad", align: "center", value: "cantidad" },
        { text: "Inicio", value: "inicio" },
        { text: "Fin", value: "fin" },
        { text: "Acciones", align: "center", value: "actions" },
      ],
    };
  },
  computed: {
    etapasPorcent() {
      return this.items.reduce((a, c) => Number(a) + Number(c.porcentaje), 0);
    },
  },
  methods: {
    eliminarRegistro(registro) {
      const arreglo = this.items.filter(
        (item) => item.etapa !== registro.etapa
      );
      this.$emit("eliminar", arreglo);
    },
  },
};
</script>