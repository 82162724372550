<template>
  <section>
    <v-layout>
      <v-flex>
        <v-row class="flex-column ma-5">
          <div>{{ `01-2022-S424` }}</div>
          <div>{{ `Solicitud de compra por convenio Marco` }}</div>
          <div class="text-caption">
            {{ `Unidad de Retenciones de Talnto Humano` }}
          </div>
          <div class="text-caption">{{ `Ejercicio 2022` }}</div>
        </v-row>
        <v-row class="ma-5 flex-column">
          <div class="mb-2">Lista de proveedores</div>
          <v-col cols="12" md="9" lg="6">
            <v-data-table :headers="headers" :items="items" hide-default-footer>
              <template #[`item.acciones`]="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="$router.push('/detalles-proveedor')"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span> Ver </span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push('/creacion-orden-compra')"
                      icon
                      v-if="item.estado_proveedor === 'Pendiente'"
                    >
                      <v-icon>mdi-clipboard-check-outline</v-icon>
                    </v-btn>
                  </template>
                  <span> Crear </span>
                </v-tooltip>
              </template>
              <template #[`item.estado_proveedor`]="{ item }">
                <v-chip
                  v-if="item.estado_proveedor === 'Pendiente'"
                  color="yellow"
                  >{{ item.estado_proveedor }}</v-chip
                >
                <v-chip
                  v-else-if="item.estado_proveedor === 'Contratado'"
                  color="secondary"
                  >{{ item.estado_proveedor }}</v-chip
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </section>
</template>
<script>
import PerfilProveedor from "../../components/PerfilProveedorComponent.vue";
export default {
  name: "listaProveedoresView",
  components: { PerfilProveedor },
  data() {
    return {
      headers: [
        { text: "Correlativo", value: "correlativo" },
        { text: "Proveedor", value: "proveedor" },
        { text: "Estado de proveedor", value: "estado_proveedor" },
        { text: "Acciones", align: "center", value: "acciones" },
      ],
      items: [
        {
          correlativo: 1,
          proveedor: "Amazon",
          estado_proveedor: "Pendiente",
        },
        {
          correlativo: 2,
          proveedor: "Ebay",
          estado_proveedor: "Contratado",
        },
      ],
    };
  },
};
</script>
